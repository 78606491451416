import { CSSProperties } from 'react';

// MUI
import { createTheme } from '@mui/material';

// Styles
import {
    adjustHoursColor
    , altDivider
    , bidActivityColor
    , cancelledColor
    , darkGray
    , disputedColor
    , dividerGray
    , emptyStarRatingColor
    , grayAccent
    , headerBorderGray
    , hoverBackground
    , inactiveColor
    , lightGray
    , lightGrayAccent
    , linearGradient
    , newBoxShadow
    , paidColor
    , placeholderGray
    , raiseColor
    , ratingColor
    , selectedBackgroundColor
    , starRatingColor
    , unfulfilledColor
    , veryableBlack
    , veryableBlue
    , veryableBlueBackgroundOpaque
    , veryableBlueBackgroundOpaque2
    , veryableBlueDisabled
    , veryableBlueHighlight
    , veryableBlueHover
    , veryableBlueOpaque
    , veryableBrightBlue
    , veryableBrightGreen
    , veryableBrightGreenAccent
    , veryableBrightGreenDisabled
    , veryableBrightGreenHover
    , veryableDarkGreen
    , veryableGray
    , veryableGrayDisabled
    , veryableGrayHover
    , veryableGreen
    , veryableGreenDisabled
    , veryableGreenHover
    , veryableLightBlue
    , veryableOrange
    , veryableOrangeBackground
    , veryableOrangeDisabled
    , veryableOrangeHover
    , veryablePink
    , veryablePinkAccent
    , veryablePinkDisabled
    , veryablePinkHover
    , veryablePinkLightAccent
    , veryablePurple
    , veryablePurpleAccent
    , veryablePurpleDisabled
    , veryablePurpleExtraDark
    , veryablePurpleHover
    , veryableRed
    , veryableRedBackground
    , veryableRedDisabled
    , veryableRedHover
    , veryableWhite
    , veryableYellow
    , veryableYellowDisabled
    , veryableYellowHover
    , whiteColor
} from '@/theme/globalStyles';

// Utils
import { pxrem } from '@/utils/pxrem';

const textFontSize = '0.75rem';

type CssColorProperties = NonNullable<CSSProperties['color']>;

declare module '@mui/material/styles' {

    export interface TypeBackground {
        default: string;
        paper: string;
        header: string;
    }
    export interface Palette {
        altBlue: {
            lightBlue: CssColorProperties;
            highlight: CssColorProperties;
            brightBlue: CssColorProperties;
            background: CssColorProperties;
            secondBackground: CssColorProperties;
            opaque: CssColorProperties;
        };
        red: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            background: CssColorProperties;
            highlight: CssColorProperties;
            bannerBg: CssColorProperties;
        };
        pink: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            accent: CssColorProperties;
            lightAccent: CssColorProperties;
        };
        purple: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            extraDark: CssColorProperties;
            accent: CssColorProperties;
        };
        orange: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            background: CssColorProperties;
            highlight: CssColorProperties;
            contrastText: CssColorProperties;
        };
        yellow: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
        };
        brightGreen: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            accent: CssColorProperties;
        };
        darkGreen: {
            main: CssColorProperties;
        };
        gray: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            hoverBackground: CssColorProperties;
            hover: CssColorProperties;
            accent: CssColorProperties;
            lightAccent: CssColorProperties;
            disabled: CssColorProperties;
            divider: CssColorProperties;
            headerBorder: CssColorProperties;
            placeholder: CssColorProperties;
            altDivider: CssColorProperties;
            secondary: CssColorProperties;
            titles: CssColorProperties;
            section: CssColorProperties;
        };
        starRating: {
            main: CssColorProperties;
            empty: CssColorProperties;
            background: CssColorProperties;
        };
        actions: {
            adjustHours: CssColorProperties;
            bidActivity: CssColorProperties;
            rating: CssColorProperties;
            paid: CssColorProperties;
            raise: CssColorProperties;
            cancelled: CssColorProperties;
            disputed: CssColorProperties;
            unfulfilled: CssColorProperties;
            inactive: CssColorProperties;
            white: CssColorProperties;
        };
        background: {
            default: CssColorProperties;
            paper: CssColorProperties;
            header: CssColorProperties;
        };
        tertiary: {
            main: CssColorProperties;
            dark: CssColorProperties;
            light: CssColorProperties;
            highlight: CssColorProperties;
            contrastText: CssColorProperties;
        };
    }
    export interface PaletteOptions {
        altBlue?: {
            lightBlue: CssColorProperties;
            highlight: CssColorProperties;
            brightBlue: CssColorProperties;
            background: CssColorProperties;
            secondBackground: CssColorProperties;
            opaque: CssColorProperties;
        };
        red?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            background?: CssColorProperties;
            highlight?: CssColorProperties;
            contrastText?: CssColorProperties;
            bannerBg?: CssColorProperties;
        };
        pink?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            accent: CssColorProperties;
            lightAccent: CssColorProperties;
        };
        purple?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            extraDark: CssColorProperties;
            accent: CssColorProperties;
        };
        orange?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            contrastText?: CssColorProperties;
            background?: CssColorProperties;
            highlight?: CssColorProperties;
        };
        yellow?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
        };
        brightGreen?: {
            main: CssColorProperties;
            light: CssColorProperties;
            dark: CssColorProperties;
            accent: CssColorProperties;
        };
        darkGreen?: {
            main: CssColorProperties;
        };
        gray?: {
            main: CssColorProperties;
            light?: CssColorProperties;
            dark?: CssColorProperties;
            hoverBackground?: CssColorProperties;
            hover?: CssColorProperties;
            accent?: CssColorProperties;
            lightAccent?: CssColorProperties;
            disabled: CssColorProperties;
            divider: CssColorProperties;
            headerBorder?: CssColorProperties;
            placeholder?: CssColorProperties;
            altDivider?: CssColorProperties;
            secondary?: CssColorProperties;
            titles?: CssColorProperties;
            section?: CssColorProperties;
        };
        starRating?: {
            main: CssColorProperties;
            empty: CssColorProperties;
            background: CssColorProperties;
        };
        actions?: {
            adjustHours: CssColorProperties;
            bidActivity: CssColorProperties;
            rating: CssColorProperties;
            paid: CssColorProperties;
            raise: CssColorProperties;
            cancelled: CssColorProperties;
            disputed: CssColorProperties;
            unfulfilled: CssColorProperties;
            inactive: CssColorProperties;
            white: CssColorProperties;
        };
        tertiary?: {
            main?: CssColorProperties;
            dark?: CssColorProperties;
            light?: CssColorProperties;
            highlight?: CssColorProperties;
            contrastText?: CssColorProperties;
            bannerBg?: CssColorProperties;
        };
    }

    interface TypographyVariants {
        caption: CSSProperties;
        finePrint: CSSProperties;
        subtitle3?: CSSProperties;
        subtitle4?: CSSProperties;
        subtitle5?: CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        caption?: CSSProperties;
        finePrint?: CSSProperties;
        subtitle3?: CSSProperties;
        subtitle4?: CSSProperties;
        subtitle5?: CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        caption: true;
        finePrint: true;
        subtitle3: true;
        subtitle4: true;
        subtitle5: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gray: true;
    }
}

const theme = createTheme( {
    palette: {
        primary: {
            main: veryableBlue
            , light: veryableBlueDisabled
            , dark: veryableBlueHover
        }
        , secondary: {
            main: veryableGreen
            , light: veryableGreenDisabled
            , dark: veryableGreenHover
        }
        , altBlue: {
            lightBlue: veryableLightBlue
            , highlight: veryableBlueHighlight
            , brightBlue: veryableBrightBlue
            , background: veryableBlueBackgroundOpaque
            , secondBackground: veryableBlueBackgroundOpaque2
            , opaque: veryableBlueOpaque
        }
        , red: {
            main: veryableRed
            , light: veryableRedDisabled
            , dark: veryableRedHover
            , background: veryableRedBackground
        }
        , pink: {
            main: veryablePink
            , light: veryablePinkDisabled
            , dark: veryablePinkHover
            , accent: veryablePinkAccent
            , lightAccent: veryablePinkLightAccent
        }
        , purple: {
            main: veryablePurple
            , light: veryablePurpleDisabled
            , dark: veryablePurpleHover
            , extraDark: veryablePurpleExtraDark
            , accent: veryablePurpleAccent
        }
        , orange: {
            main: veryableOrange
            , light: veryableOrangeDisabled
            , dark: veryableOrangeHover
            , background: veryableOrangeBackground
        }
        , warning: {
            main: veryableOrange
            , light: veryableOrangeDisabled
            , dark: veryableOrangeHover
        }
        , error: {
            main: veryableRed
            , light: veryableRedDisabled
            , dark: veryableRedHover
        }
        , yellow: {
            main: veryableYellow
            , light: veryableYellowDisabled
            , dark: veryableYellowHover
        }
        , brightGreen: {
            main: veryableBrightGreen
            , light: veryableBrightGreenDisabled
            , dark: veryableBrightGreenHover
            , accent: veryableBrightGreenAccent
        }
        , darkGreen: {
            main: veryableDarkGreen
        }
        , common: {
            black: veryableBlack
            , white: veryableWhite
        }
        , gray: {
            main: veryableGray
            , light: lightGray
            , dark: darkGray
            , hoverBackground: hoverBackground
            , hover: veryableGrayHover
            , accent: grayAccent
            , lightAccent: lightGrayAccent
            , disabled: veryableGrayDisabled
            , divider: dividerGray
            , headerBorder: headerBorderGray
            , placeholder: placeholderGray
            , altDivider: altDivider
        }
        , starRating: {
            main: starRatingColor
            , empty: emptyStarRatingColor
            , background: selectedBackgroundColor
        }
        , actions: {
            adjustHours: adjustHoursColor
            , bidActivity: bidActivityColor
            , rating: ratingColor
            , paid: paidColor
            , raise: raiseColor
            , cancelled: cancelledColor
            , disputed: disputedColor
            , unfulfilled: unfulfilledColor
            , inactive: inactiveColor
            , white: whiteColor
        }
        , text: {
            primary: veryableGray
            , secondary: darkGray
        }
        , background: {
            header: linearGradient
        }
    }
    , typography: {
        fontFamily: [
            'Avenir Next'
            , 'Open Sans'
            , 'sans-serif'
        ].join( ',' )
        , fontSize: 14
        , h1: {
            color: veryableBlue
            , fontSize: pxrem( 30 )
            , fontWeight: 600
        }
        , h2: {
            color: darkGray
            , fontSize: pxrem( 24 )
            , fontWeight: 600
        }
        , h3: {
            color: darkGray
            , fontSize: pxrem( 20 )
            , fontWeight: 600
        }
        , h4: {
            fontSize: pxrem( 18 )
            , fontWeight: 600
            , lineHeight: pxrem( 26 )
            , color: darkGray
        }
        , h5: {
            fontSize: pxrem( 16 )
            , fontWeight: 600
            , lineHeight: pxrem( 24 )
            , letterSpacing: '0em'
            , textAlign: 'left'
        }
        , h6: {
            color: darkGray
            , fontSize: pxrem( 14 )
            , fontWeight: 600
        }
        , button: {
            fontWeight: 600
        }
        , body1: {
            fontSize: pxrem( 14 )
        }
        , body2: {
            fontSize: pxrem( 13 )
        }
        , caption: {
            fontSize: pxrem( 12 )
        }
        , finePrint: {
            fontSize: pxrem( 10 )
        }
    }
    , components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: lightGrayAccent
                }
            }
        }
        , MuiGrid: {
            styleOverrides: {
                'spacing-xs-1': {
                    marginTop: 0
                }
                , 'spacing-xs-2': {
                    marginTop: 0
                }
                , 'spacing-xs-3': {
                    marginTop: 0
                }
                , 'spacing-xs-4': {
                    marginTop: 0
                }
                , 'spacing-xs-5': {
                    marginTop: 0
                }
                , 'spacing-xs-6': {
                    marginTop: 0
                }
            }
        }
        , MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    boxSizing: 'border-box'
                    , height: 'auto'
                    , background: veryableWhite
                    , fontSize: textFontSize
                    , paddingTop: 13
                    , paddingBottom: 13
                    , color: darkGray
                    , '&.Mui-disabled': {
                        background: lightGrayAccent
                        , cursor: 'not-allowed'
                    }
                    , '&::placeholder': {
                        color: placeholderGray
                        , opacity: 1
                    }
                    , MozAppearance: 'textfield'
                    , '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none'
                        , MozAppearance: 'textfield'
                    }
                }
            }
        }
        , MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.9rem'
                    , color: veryableGray
                }
            }
        }
        , MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    lineHeight: 1
                    , '&.MuiInputLabel-shrink': {
                        lineHeight: 1.1
                    }
                }
            }
        }
        , MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: textFontSize
                }
            }
        }
        , MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 2
                }
                , elevation2: {
                    boxShadow: newBoxShadow
                }
            }
        }
        , MuiStep: {
            styleOverrides: {
                horizontal: {
                    paddingLeft: 0
                    , paddingRight: 0
                }
            }
        }
        , MuiStepIcon: {
            styleOverrides: {
                text: {
                    fontWeight: 700
                    , fontSize: '0.60rem'
                }
                , root: {
                    '&.Mui-completed': {
                        color: veryableGreen
                        , fontWeight: 600
                    }
                }
            }
        }
        , MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: '.8rem'
                    , fontWeight: 600
                    , marginTop: '1rem'
                    , textAlign: 'center'
                    , color: darkGray
                    , '&.Mui-active': {
                        color: veryableBlue
                        , fontWeight: 600
                    }
                    , '&.Mui-completed': {
                        color: darkGray
                        , fontWeight: 600
                    }
                }
                , iconContainer: {
                    paddingRight: 0
                }
            }
        }
        , MuiStepConnector: {
            styleOverrides: {
                root: {
                    '&.Mui-completed': {
                        '& .MuiStepConnector-line': {
                            borderColor: dividerGray
                        }
                    }
                    , '&.Mui-active': {
                        '& .MuiStepConnector-line': {
                            borderColor: dividerGray
                        }
                    }
                }
            }
        }
        , MuiChip: {
            styleOverrides: {
                root: {
                    marginRight: 12
                    , marginBottom: 12
                    , paddingLeft: 6
                    , paddingRight: 6
                    , fontWeight: 600
                    , boxShadow: newBoxShadow
                }
                , clickable: {
                    marginRight: 10
                    , fontSize: textFontSize
                    , fontWeight: 500
                    , color: darkGray
                    , boxShadow: 'none'
                }
                , deletable: {
                    backgroundColor: veryablePurple
                    , color: veryableWhite
                    , '& $icon': {
                        marginRight: -3
                        , fontSize: 13
                        , color: veryableWhite
                    }
                }
                , deleteIcon: {
                    position: 'relative'
                    , left: 6
                    , color: veryableWhite
                    , '&:hover': {
                        color: lightGrayAccent
                    }
                }
            }
        }
        , MuiDrawer: {
            styleOverrides: {
                paperAnchorRight: {
                    width: 350
                    , height: '100vh'
                    , overflowX: 'hidden'
                    , paddingBottom: 60
                    // Accounts for absolutely position footer
                    , '& header': {
                        position: 'relative'
                        , padding: 20
                        , display: 'flex'
                        , alignItems: 'center'
                        , height: 77
                        , backgroundImage: linearGradient
                        , color: veryableWhite
                        , '& h3': {
                            color: veryableWhite
                            , fontSize: '1rem'
                        }
                    }
                    , '& main': {
                        position: 'relative'
                        , width: '100%'
                        , height: 'calc(100vh - 77px - 35px)'
                        , overflowY: 'scroll'
                        , paddingTop: 32
                        , paddingLeft: 20
                        , paddingRight: 20
                        , paddingBottom: 67
                        , '&.no-footer': {
                            height: 'calc(100vh - 77px)'
                            , paddingBottom: 20
                        }
                    }
                    , '& footer': {
                        position: 'absolute'
                        , bottom: 24
                        , display: 'flex'
                        , alignItems: 'center'
                        , justifyContent: 'center'
                        , width: '100%'
                    }

                }
            }
        }
        , MuiButton: {
            styleOverrides: {
                contained: {
                    width: 150
                    , minHeight: 35
                    , marginRight: 12
                    , backgroundColor: veryableGray
                    , color: veryableWhite
                    , borderRadius: 2
                    , fontWeight: 600
                    , fontSize: 13
                    , '&:hover': {
                        backgroundColor: darkGray
                        , color: veryableWhite
                    }
                    , '&.Mui-disabled': {
                        backgroundColor: veryableGrayDisabled
                        , color: veryableWhite
                    }
                    , '&:last-child': {
                        marginRight: 0
                    }
                }
                , containedPrimary: {
                    backgroundColor: veryableBlue
                    , color: veryableWhite
                    , '&:hover': {
                        backgroundColor: veryableBlueHover
                        , color: veryableWhite
                    }
                    , '&.Mui-disabled': {
                        backgroundColor: veryableBlueDisabled
                        , color: veryableWhite
                    }
                }
                , containedSecondary: {
                    backgroundColor: veryableGreen
                    , color: veryableWhite
                    , '&:hover': {
                        backgroundColor: veryableGreenHover
                        , color: veryableWhite
                    }
                    , '&.Mui-disabled': {
                        backgroundColor: veryableGreenDisabled
                        , color: veryableWhite
                    }
                }
                , colorInherit: {
                    color: veryableWhite
                    , '&:hover': {
                        color: veryableWhite
                    }
                    , '&.Mui-disabled': {
                        color: veryableWhite
                    }
                }
                , outlined: {
                    width: 150
                    , height: 35
                    , marginRight: 12
                    , borderRadius: 2
                    , fontWeight: 600
                    , fontSize: 13
                    , '&:last-child': {
                        marginRight: 0
                    }
                }
            }
        }
        , MuiIcon: {
        }
        , MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: veryableBlue
                    , '&:hover': {
                        color: veryableBlueHover
                    }
                }
                , colorSecondary: {
                    color: darkGray
                    , '&:hover': {
                        color: veryableBlack
                    }
                }
                , colorDisabled: {
                    color: veryableGrayDisabled
                }
                // Use action for inverting icon color
                , colorAction: {
                    color: veryableWhite
                    , '&:hover': {
                        color: lightGrayAccent
                    }
                }
            }
        }
        , MuiFormGroup: {
            styleOverrides: {
                root: {
                    '&[role="radiogroup"]': {
                        flexDirection: 'row'
                    }
                }
            }
        }
        , MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'inherit'
                        , color: veryableBlue
                        , '&::after': {
                            content: '""'
                            , position: 'absolute'
                            , right: '1rem'
                            , height: '1rem'
                            , width: '1rem'
                            , backgroundImage: `url( ${ 'https://veryable-public-assets.s3.us-east-2.amazonaws.com/business-portal-next/check.svg' } )`
                            , backgroundSize: 'contain'
                            , backgroundRepeat: 'no-repeat'
                        }
                    }
                }
            }
        }
        , MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: 0
                    , minWidth: 190
                    , paddingTop: 6
                    , paddingBottom: 6
                    , fontSize: textFontSize
                    , color: darkGray
                }
            }
        }
        , MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '.875rem'
                }
            }
        }
        , MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: 14
                    , marginBottom: 36
                }
            }
        }
        , MuiSelect: {
            styleOverrides: {
                select: {
                    selectMenu: {
                        paddingTop: 13
                        , paddingBottom: 13
                        , fontSize: textFontSize
                    }
                }
                , icon: {
                    marginRight: '3%'
                }
            }
        }
        , MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: textFontSize
                }
            }
        }
        , MuiAppBar: {
            styleOverrides: {
                root: {
                    height: 42
                }
                , colorSecondary: {
                    backgroundColor: placeholderGray
                    , color: veryableWhite
                }
            }
        }
        , MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 42
                    , '&.MuiTab-textColorPrimary': {
                        color: veryableWhite
                    }
                }
                , indicator: {
                    backgroundColor: veryableWhite
                }
            }
        }
        , MuiTab: {
            styleOverrides: {
                root: {
                    padding: '0 15px'
                    , minHeight: 42
                    , minWidth: 'unset'
                    , fontSize: `${ 11 / 16 }rem`
                }
                , textColorPrimary: {
                    color: veryableWhite
                    , '&:hover': {
                        color: veryableWhite
                    }
                    , '&.Mui-selected': {
                        color: veryableWhite
                    }
                }
            }
        }
        , MuiTableCell: {
            styleOverrides: {
                head: {
                    paddingBottom: 11
                    , fontSize: `${ 13 / 16 }rem`
                    , textTransform: 'uppercase'
                    , color: darkGray
                    , fontWeight: 600
                }
                , root: {
                    paddingLeft: 0
                }
            }
        }
        , MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: lightGrayAccent
                        , color: veryableGrayDisabled
                    }
                }
                , disabled: {
                }
            }
        }
        , MuiCheckbox: {
            // Formik uses secondary color when using Field as Checkbox
            styleOverrides: {
                colorSecondary: {
                    '&.Mui-checked': {
                        color: veryableBlue
                    }
                }
            }
        }
    }
} );

export default theme;
